import Blocks from "@/components/Blocks"
import Block from "@/components/wrappers/block"
import styles from "./buttons.module.css"
import classNames from "classnames/bind"

export default function Buttons({ block, settings }) {
  const cx = classNames.bind(styles)
  const { layout } = block.attrs

  if (block.innerBlocks.length === 0) {
    return null
  }

  let width = settings?.width ?? "column"

  const classes = {
    wrapper: true,
    wrap: layout?.flexWrap === "wrap",
    center: layout?.justifyContent === "center",
    left: layout?.justifyContent === "left",
    right: layout?.justifyContent === "right",
    between: layout?.justifyContent === "space-between",
    row: layout?.orientation === "horizontal",
    col: layout?.orientation === "vertical",
    spacing: layout?.flexWrap !== "wrap" && block.innerBlocks.length > 1
  }

  return (
    <Block
      block={block}
      width={width}
      gap="small"
      className={cx(classes, settings?.innerAlign)}>
      <Blocks
        blocks={block.innerBlocks}
        settings={{
          "core/button": {
            layout: layout,
            multiple: block.innerBlocks.length > 1
          }
        }}
      />
    </Block>
  )
}
